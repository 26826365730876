import React from "react"
import RealizationInfo from "../../components/Organisms/RealizationInfo/RealizationInfo"
import RealizationPhotos from "../../components/Molecules/realizationPhotos/RealizationPhotos"
import { graphql } from "gatsby"
import photo1 from "../../assets/images/realizations/zambrow/1.jpg"
import photo2 from "../../assets/images/realizations/zambrow/2.jpg"
import photo3 from "../../assets/images/realizations/zambrow/3.jpg"
import gizycko from "../../assets/images/realizations/gizycko/gizycko.jpg"
import lomza from "../../assets/images/realizations/lomza/lomza.jpg"
import preili from "../../assets/images/realizations/preili/preili.jpg"
import OtherRealizations from "../../components/Organisms/OtherRealizations/OtherRealizations"
import CTABanner from "../../components/Organisms/CTABanner/CTABanner"
import { WithIntl } from "../../components/Atoms/withTranslate"
import { localize } from "../../components/Atoms/Language"


const ZambrowPage = ({ data, language }) => {
  const images = [photo1, photo2, photo3]
  const otherRealizations = [
    {
      image: gizycko,
      name: localize("291"),
      type: localize("295"),
      url: "gizycko",
    },
    {
      image: lomza,
      name: localize("292"),
      type: localize("296"),
      url: "lomza",
    },
    {
      image: preili,
      name: localize("293"),
      type: localize("296"),
      url: "preili",
    },
  ]

  return (
    <>
      <RealizationInfo open={1} closed={3} name="zambrow" image={data.file.childImageSharp.fixed} language={language}>
        <li className="gray600">{localize("311")}</li>
        <li className="gray600">{localize("312")}</li>
        <li className="gray600">{localize("313")}</li>
        <li className="gray600">{localize("336")}</li>
        <li className="gray600">{localize("337")}</li>
      </RealizationInfo>
      <RealizationPhotos images={images}/>
      <OtherRealizations realizations={otherRealizations} language={language}/>
      <CTABanner language={language}/>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "zambrow" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default WithIntl()(ZambrowPage)
